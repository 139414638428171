@import "var";

.des__home__sec{
    // background-color: red;
    .des__card{
        height: 300px;
        position: relative;
        img{
            box-shadow: 2px 2px 10px 1px rgba(168, 168, 168, 0.945);
            height: 100%;
            object-fit: cover;
        }
        .overlay{
            position: absolute;
            top: 0;
            left: 4%;
            right: 4%;
            bottom: 0;
            background-color: rgba(41, 41, 41, 0.425);
        }
        .title{
            position: absolute;
            inset: 0;
            display: flex;
            display: flex;
            justify-content: center;
            cursor: pointer;
            
            &::after{
                content: "";
                height: 4px;
                width: 30px;
                background-color: $white;
                position: absolute;
                bottom: 9%;
                left: 45%;
                border-radius: 36px;
                transition: 0.6s all;
            }
            h5{
                color: $white;
                position: absolute;
                bottom: 10%;
                transition: 0.6s all;
            }
            &:hover{
                h5{
                    transform: translateY(-20px);
                    color: $brand;
                    
                }
                &::after{
                    width: 40px;
                    left: 43%;
                    background-color: $brand;
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .des__card{
        padding-top: 20px !important;
        .overlay{
        top: 7% !important;
        left: 3% !important;
        right: 3% !important;
        }
    }
}
.btn__con__des{
    // background-color: red;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    
    
}