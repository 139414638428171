@import "var";

.tour__slide__sec{
    .swiper-slide{
        box-shadow: 1px 1px 20px 1px rgba(168, 168, 168, 0.13);
        background-color: $white;
    }
    .swiper-button-prev, .swiper-button-next{
        background-color: #99be64a4;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        transition: 0.4s;
        &::after{
            color: $white;
            font-size: 16px;
        }
        &:hover{
            background-color: $brand;
        }
    }
    .desc{
        p{
            font-weight: 600;
            font-size: 20px;
            color: $black;
            del{
                color: gray;
                font-weight: 400;
                font-size: 18px;
            }
        }
        .price{
            color: $brand;
            line-height: 46px;
        }
        .name{
            font-size: 26px;
            line-height: 46px;
        }
        .duration{
            font-size: 16px;
            p{
                font-size: 16px;
                color: rgb(75, 75, 75);
            }
        }
        .inclusion{
            h4{
                font-size: 20px;
            }
            ul{
               li{
                line-height: 32px;
                display: flex;
                gap: 12px;
                i{
                    color: $brand;
                }
               }
            }
        }
    }
 
}