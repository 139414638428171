@import "var";

.deal__sec{
    padding-bottom: 40px;
    padding-top: 40px;
    .swiper-slide{
        box-shadow: 1px 1px 20px 1px rgba(168, 168, 168, 0.13);
        background-color: $white;
    }
    .swiper-button-prev, .swiper-button-next{
        background-color: #99be64a4;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        transition: 0.4s;
        &::after{
            color: $white;
            font-size: 16px;
        }
        &:hover{
            background-color: $brand;
        }
    }
    .img__con{
        overflow: hidden;
        img{
            width: 100%;
            object-fit: cover;
            transition: 0.9s all;
        }
    }
    .swiper-slide{
        &:hover{
            img{
                transform: scale(1.3);
            }
            .discount__con{
                &:after{
                    background-color: $brand;
                    color: $white;
                }
            }
        }
    }
    .desc{
        padding: 40px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        p{
            font-weight: 600;
            font-size: 18px;
            color: $black;
            del{
                color: gray;
                font-weight: 400;
                font-size: 16px;
            }
        }
        .discount__con{
            background-color: $white;
            color: $brand;
            padding: 30px;
            border-radius: 50%;
            position: absolute;
            font-size: 18px;
            font-weight: 500;
            top: -20%;
            &::after{
                content: "Off";
                background-color: $white;
                padding: 10px;
                border-radius: 50%;
                position: absolute;
                top: -5%;
                right: -20%;
                color: $brand;
                font-size: 13px;
                font-weight: 500;
                transition: 0.9s all;
            }
        }
    }
        // .find__now{
        //     background-color: $white;
        //     border-color: $brand;
        //     border-radius: 0;
        //     width: 180px;
        //     min-height: 55px;
        //     color: $brand;
        //     position: relative;
        //     overflow: hidden;
        //     .label {
        //         font-family: $para__font;
        //         position: relative;
        //         font-size: 18px;
        //         font-weight: 500;
        //         top: -1px;
        //       }
        //       .transition {
        //         transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
        //         transition-duration: 0.8s;
        //         background-color: $brand;
        //         border-radius: 9999px;
        //         width: 0;
        //         height: 0;
        //         position: absolute;
        //         left: 50%;
        //         top: 50%;
        //         transform: translate(-50%, -50%);
        //       }
        //       &:hover{
        //         .transition{
        //             height: 15em;
        //             width: 15em;
        //         }
        //         .label{
        //             color: $white;
        //         }
        //       }
        //       &:active{
        //         transform: scale(2);
        //       }
              
        // }
    }
