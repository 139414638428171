@import "../var";

.about__page{
    .text{
        p{
            font-size: 18px;
            color: gray;
        }
        ul{
            list-style: disc;
            padding-left: 18px;
            li{
                font-size: 18px;
                color: rgb(87, 87, 87);
                font-weight: 500;
            }
        }
    }
   
    .img__con2{
        position: relative;
        .two{
            position: absolute;
            top: 15%;
            width: 270px;
        }
    }
    .guides{
        .card__item{
            box-shadow: 1px 1px 20px 1px rgba(109, 109, 109, 0.13);
            .card__con{
                overflow: hidden;
                img{
                    transition: 0.6s all linear;
                }
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            p{
                font-size: 18px;
                color: gray;
            }
        }
    }
    .find__now{
        background-color: $white;
        border-color: $brand;
        border-radius: 0;
        width: 180px;
        min-height: 55px;
        color: $brand;
        position: relative;
        overflow: hidden;
        .label {
            font-family: $para__font;
            position: relative;
            font-size: 18px;
            font-weight: 500;
            top: -1px;
          }
          .transition {
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-duration: 0.8s;
            background-color: $brand;
            border-radius: 9999px;
            width: 0;
            height: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &:hover{
            .transition{
                height: 15em;
                width: 15em;
            }
            .label{
                color: $white;
            }
          }
          &:active{
            transform: scale(2);
          }
          
    }
}
