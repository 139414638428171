@import 'var';

.footer__sec {
  // height: 100vh;
  background-image: url('../../public/assets/images/footer-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgb(0 0 0 / 63%);
    width: 100%;
    z-index: 1;
  }
}
.circle__con {
  // background-color: red;
  z-index: 2;

  .footer__circle {
    background-color: $black;
    height: 280px;
    width: 280px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    gap: 12px;
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-image: url('https://demo.egenslab.com/html/astrip/preview/assets/images/icons/footer-plane.svg');
      background-size: 100px;
      background-repeat: no-repeat;
      background-position: 50% 25%;
      opacity: 0.2;
    }
    img {
      width: 150px;
      height: 130px;
    }
    p {
      color: $brand;
      font-size: 18px;
    }
    .text {
      position: absolute;
      inset: 0;
      color: $white;
      padding-top: 10px;
      // background-color: rgba(0, 0, 255, 0.273);
      animation: rotateText 30s linear infinite;
      h6 {
        position: absolute;
        left: 50%;
        font-size: 1.2em;
        transform-origin: 0 130px;
      }
    }
  }
}
@media (max-width: 1199px) {
  .footer__circle {
    height: 215px !important;
    width: 215px !important;
    .text {
      padding-top: 6px !important;
      h6 {
        transform-origin: 0 103px !important;
        font-size: 1em !important;
      }
    }
  }
}
@keyframes rotateText {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.footer__info__con {
  z-index: 2;
  display: flex;
  // justify-content: center;
  // background-color: rgba(0, 0, 0, 0.297);
  .footer__title {
    font-size: 1.8rem;
    font-weight: 500;
    color: $white;
  }
  ul {
    li {
      padding: 8px 0px;
      color: rgb(252, 252, 252);
      font-weight: 500;
      font-size: 17px;
      i {
        color: $brand;
        border: 1px solid $brand;
        padding: 10px 14px;
        border-radius: 50%;
        display: block;
        transition: 0.6s all;
      }
      &:hover {
        i {
          color: $white;
          background-color: $brand;
        }
      }
    }
  }
}
.footer__middle {
  z-index: 2;
  color: $white;
  p {
    font-size: 20px;
    font-weight: 500;
  }
  i {
    background-color: $black;
    padding: 10px 12px;
    border-radius: 50%;
    font-size: 14px;
    color: $white;
    border: 1px solid rgb(194, 194, 194);
    transition: 0.6s all;
    &:hover {
      background-color: $brand;
      border: 1px solid $brand;
    }
  }
}
.footer__low__con {
  border-top: 1px solid rgb(255, 255, 255);
}
.footer__lower {
  z-index: 2;
  font-size: 14px;
  p {
    color: $white;
  }
  span {
    color: $brand;
  }
  li {
    color: $white;
  }
}
