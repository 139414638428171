@import "var";

.blog__home__sec{
    .img__con{
        position: relative;
        p{
            position: absolute;
            bottom: 8%;
            left: 5%;
            color: $black;
            background-color: $white;
            padding: 6px 8px;
        }
    }
    .text{
        a{
            color: gray;
            border-bottom: 2px solid gray;
            border-style: none none dashed none;
            transition: 0.6s all;
            cursor: pointer;
            &:hover{
                color: $brand;
                border-bottom: 2px solid $brand;
            border-style: none none dashed none;

            }
        }
    }
}