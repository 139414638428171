@import 'var';

.header__sec {
  box-shadow: 0px 14px 20px -14px rgb(218, 218, 218);
  position: relative;
  z-index: 99;
  .row {
    height: 70px;
    img {
      width: 120px;
      cursor: pointer;
    }
    a {
      font-weight: 600;
      font-size: 16px;
      color: $black;
      i {
        font-size: 14px;
      }
    }
    i {
      font-size: 30px;
      color: $black;
    }
    p {
      font-size: 14px;
    }
    p:nth-child(2) {
      font-weight: 900;
      color: $black;
    }
    .menu {
      li {
        cursor: pointer;
        &:hover {
          a {
            color: $brand;
            &::after {
              display: block;
            }
          }
        }
      }
      a {
        transition: 0.5s ease-in-out;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: -10%;
          left: 0;
          right: 0;
          height: 2px;
          background: linear-gradient(90deg, $brand, transparent);
          display: none;
          animation: underline 0.5s linear;
          transition: 0.5s ease-in-out;
        }
      }
      li:nth-child(2),
      li:nth-child(4) {
        position: relative;
        .tour__box {
          visibility: hidden;
          position: absolute;
          top: 90%;
          left: -60%;
          right: -60%;
          animation: show 0.5s linear;
          transition: 0.5s ease-in-out;
          .white {
            background-color: transparent;
            width: 100%;
            height: 30px;
          }
          .sub__con {
            background-color: $black;
            color: $white;
            height: 100%;
            width: 100%;
          }
        }
        &:hover {
          .tour__box {
            visibility: visible;
          }
        }
        &:not(:hover) {
          .tour__box {
            animation-name: hide;
            animation-duration: 0.5s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
          }
        }
      }
      li:nth-child(3) {
        position: relative;
        .tour__box {
          visibility: hidden;
          position: absolute;
          top: 90%;
          left: -20%;
          right: -20%;
          animation: show 0.5s linear;
          transition: 0.5s ease-in-out;
          .white {
            background-color: transparent;
            width: 100%;
            height: 30px;
          }
          .sub__con {
            background-color: $black;
            color: $white;
            height: 100%;
            width: 100%;
            a {
              color: $white;
            }
          }
        }
        &:hover {
          .tour__box {
            visibility: visible;
          }
        }
        &:not(:hover) {
          .tour__box {
            animation-name: hide;
            animation-duration: 0.5s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
          }
        }
      }
      .sub__menu {
        li {
          border-bottom: 1px solid rgba(187, 187, 187, 0.247);
          padding: 10px 10px;
          position: relative;
          &:hover {
            &::after {
              opacity: 1;
            }
            a {
              color: $brand !important;
            }
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: linear-gradient(90deg, $brand, transparent);
            opacity: 0;
            transition: 0.6s ease-in;
          }
        }
        a {
          color: $white !important;
          font-size: 13px;
          &::after {
            display: none !important;
          }
        }
      }
    }
  }
}
.fixed__top {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  background-color: $white;
  animation: fixedTop 0.6s linear;
  z-index: 9999;
  .row {
    height: 90px;
  }
}
@keyframes fixedTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes underline {
  0% {
    right: 300%;
  }
  100% {
    right: 0;
  }
}
@keyframes show {
  0% {
    top: 200%;
    opacity: 0;
  }
  100% {
    top: 90%;
    opacity: 100%;
  }
}
@keyframes hide {
  0% {
    top: 90%;
    opacity: 100%;
  }
  100% {
    top: 200%;
    opacity: 0%;
  }
}
